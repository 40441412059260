<template>
    <div class="main_container">
        <div class="title_container">
            <div>每日用户登录次数</div>
            <div class="right_container">
                <el-date-picker
                        class="picker"
                        value-format="timestamp"
                        size="small"
                        v-model="value1"
                        type="date"
                        placeholder="选择日期" @change="getTime">
                </el-date-picker>
                <div class="user_count">当前登录用户数:{{userCount}}人</div>
            </div>
        </div>
        <ve-line :data="chartData"></ve-line>

        <div class="title_container">用户占比</div>
        <ve-histogram :data="chartData2" :settings="chartSettings"></ve-histogram>

        <div class="title_container">用户周使用频次</div>
        <ve-histogram :data="chartData3" :settings="chartSettings3"></ve-histogram>

        <div class="title_container">
            <div>用户自学频次对比</div>
        </div>
        <ve-histogram :data="chartData4" :settings="chartSettings4"></ve-histogram>

        <div class="title_container">用户每周登录频次对比:</div>
        <ve-histogram :data="chartData5" :settings="chartSettings5"></ve-histogram>
    </div>
</template>


<script>
    import {getDateFromTimeStamp2} from '@/utils/dateUtil'

    export default {
        name: "user",

        data() {

            return {
                value1: Date.now(),//选择日期
                userCount: 0,

                chartSettings: {
                    stack: {'用户': ['独立用户占比', '班群用户占比']}
                },

                chartSettings3: {
                    metrics: ['作业', '自习'],
                    dimension: ['日期']
                },

                chartSettings4: {
                    metrics: ['班群用户', '独立用户'],
                    dimension: ['日期']
                },

                chartSettings5: {
                    metrics: ['班群用户', '独立用户'],
                    dimension: ['日期']
                },





            }
        },
        computed: {
            // 每日用户登录次数
            chartData() {
                let rows = this.$store.state.data.data1.map((item) => {
                    let row = {};
                    row['日期'] = getDateFromTimeStamp2(item.startTime);
                    row['每日用户登录次数'] = item.count;
                    return row;
                })
                if (this.userCount === 0 && rows.length > 0) {
                    this.userCount = rows[rows.length - 1]['每日用户登录次数'];
                }
                let columns = ['日期', '每日用户登录次数'];
                return {rows, columns}
            },

            // 用户占比
            chartData2() {
                let rows = this.$store.state.data.data2.map((item, index) => {
                    let row = {};
                    let total = item.groupUserCount + item.noneGroupUserCount;
                    row['日期'] = 'w' + (index + 1);
                    row['独立用户占比'] = (item.noneGroupUserCount * 100 / total);
                    row['班群用户占比'] = (item.groupUserCount * 100 / total);
                    return row;
                })
                let columns = ['日期', '独立用户占比', '班群用户占比'];
                return {rows, columns}
            },
            // 用户周使用频次
            chartData3() {
                let rows = this.$store.state.data.data3.map((item, index) => {
                    let row = {};
                    row['日期'] = 'w' + (index + 1);
                    row['作业'] = (item.homeworkCount * 100 / item.userCount);
                    row['自习'] = (item.exerciseCount * 100 / item.userCount);
                    return row;
                })
                let columns = ['日期', '作业', '自习'];
                return {rows, columns}
            },
            // 用户自学频次对比
            chartData4() {
                let rows = this.$store.state.data.data4.map((item, index) => {
                    let row = {};
                    row['日期'] = 'w' + (index + 1);
                    row['班群用户'] = (item.groupCount * 1.0 / item.groupUserCount);
                    row['独立用户'] = (item.noneCount * 1.0 / item.noneUserCount);
                    return row;
                })
                let columns = ['日期', '班群用户', '独立用户'];
                return {rows, columns}
            },
            // 用户自学频次对比
            chartData5() {
                let rows = this.$store.state.data.data5.map((item, index) => {
                    let row = {};
                    row['日期'] = 'w' + (index + 1);
                    row['班群用户'] = (item.groupCount * 1.0 / item.groupUserCount);
                    row['独立用户'] = (item.noneCount * 1.0 / item.noneUserCount);
                    return row;
                })
                let columns = ['日期', '班群用户', '独立用户'];
                return {rows, columns}
            },
        },
        mounted() {
            this.getDate1();
            this.getDate2();
            this.getDate3();
            this.getDate4();
            this.getDate5();
        },
        methods: {
            //选择日期
            getTime() {
                console.log(this.value1)
                this.getDate1();
            },
            getDate1() {
                this.$store.dispatch("data/getData1", {
                    'endTime': this.value1,
                });
            },
            getDate2() {
                this.$store.dispatch("data/getData2", {});
            },
            getDate3() {
                this.$store.dispatch("data/getData3", {});
            },
            getDate4() {
                this.$store.dispatch("data/getData4", {});
            },
            getDate5() {
                this.$store.dispatch("data/getData5", {});
            }
        }
    }
</script>

<style scoped lang="scss">
    .main_container {
        padding: 10px 20px 20px 20px;
    }

    .title_container {
        font-weight: bolder;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .right_container {
        display: flex;
        justify-content: center;
        align-items: center;

        .picker {
            width: 130px;
        }
    }

    .user_count {
        font-size: 18px;
        margin-left: 30px;
    }

</style>
